import { Link } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";

const ThematicOverview: React.FC = ({ area }) => {
  return (
    <OverviewWrapper color={area.color}>
      <div className="header">
        <div className="icon">
          <img src={area.icon} alt={area.label} />
        </div>
        <div>
          <h1>{area.label}</h1>
          <p>{area.description}</p>
        </div>
      </div>
      {!!area.children?.length && <>
      <h4>Sub-themes</h4>
      <ul>
        {area.children?.map((a) => (
          <li key={a.id}><Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${area.id}#explore+${a.id}`}>{a.label}</Link></li>
        ))}
      </ul>
      </>
    }
    </OverviewWrapper>
  );
};

export default ThematicOverview;

const OverviewWrapper = styled.div`
  color: #111111;
  .header {
    background: #f9f9f9;
    margin: -16px;
    padding: 16px;
    display: flex;
    h1 {
      color: #111111;
      padding: 6px 0 12px;
    }
    .icon {
      width: 80px;
      min-width: 36px;
      height: 80px;

      border-radius: 12px;
      margin: 0 16px 0 4px;
      img {
        width: 100%;
      }
    }
  }
  h4 {
    padding: 20px 0 10px;
    font-size: 14px;
    margin: 10px 0 0 96px;
  }
  ul {
    padding: 0 0 0 96px;
    li {
      font-size: 14px;
      list-style: none;
      margin-left: 15px;
      color: #616161;
    }
    li:before {
      display: inline-block;
      content: " ";
      background: ${({ color }) => color || "#efefef"};
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 0px 8px 1px -15px;
    }
  }
    @media(max-width: 768px){
      h4{
        margin: 10px 0 0 56px;
      }
      ul {
        padding: 0 0 0 56px;
      }
    }

`;
