/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { HomeSection, SectionHeader } from "./components";
// @ts-ignore
import { Col, Grid, Row } from "antd";
import { useEffect, useState } from "react";
import { routeDataCommonsConstants } from "../../../../../src/helper/Common/RoutePathConstants";
import { Box } from "./Box";

const useBreakpoint = Grid.useBreakpoint;

const Container = styled(HomeSection)`
  text-align: left;
`;

const Header = styled(SectionHeader)`
  width: 100%;
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  a {
    font-weight: 500;
    font-size: 14px;
    color: #283460;
    white-space: nowrap;
    display: flex;
    line-height: 25px;
    justify-content: flex-end;
  }
`;

const DescCard = styled.div`
  // padding-top: 200px;
  width: 100%;
  text-align: center;
  display: block;
  a {
    color: #283460;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #283460;
    background: #fff;
    font-weight: 500;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const MapArea = styled.div`
  display: block;
  position: relative;
  padding-bottom: 28px;
  margin-top: 24px;
  img {
    width: 100%;
  }
  ul {
    list-style: none;
  }
  ul li {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    padding: 2px;
  }
  .selected {
    border: 2px solid #fff;
    box-shadow: 0 0 0 2px #666;
  }

  .largest {
  }

  .large {
  }

  .xl {
  }

  .small {
    li {
      height: 16px;
      width: 16px;
    }
  }

  #brown {
    right: 27%;
    top: 41%;
    background-color: #aa5d00;
  }
  #blue {
    left: 7%;
    top: 34%;
    background-color: #1c4969;
  }
  #red {
    left: 44%;
    top: 47%;
    background-color: #a01d43;
  }
  #green {
    left: 56%;
    top: 52%;
    background-color: #509e3e;
  }
  #purple {
    right: 19%;
    top: 27%;
    background-color: #8e52a0;
  }
  @media (min-width: 480px) {
    #purple {
      top: 30%;
      right: 21%;
    }
  }
`;

const MapContainer = styled(Container)`
  padding: 1rem;
  a.-dc-home-countries-explore.more {
    display: flex;
    padding-top: 1rem;
  }
  @media (min-width: 820px) {
    padding: 2rem 1rem;
    > .ant-row {
      padding: 2rem 0;
      width: 100%;
    }
  }
  > .ant-row {
    padding: 1rem 0;
    width: 100%;
  }
`;
export const BlockTitle = styled(Col)`
  text-align: left;
  padding-bottom: 16px;
  line-height: 100%;
  @media (max-width: 820px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const MapSection = () => {
  const history = useHistory();
  const [selected, setSelected] = useState("brown");
  const [content, setContent] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = useBreakpoint();

  const openCard = () => {
    const item = list.find((v) => v.id == selected);

    const card =
      `<h3 style="font-size: 44px; padding-bottom: 1rem">${item?.value}</h3>` +
      `<span style="display: block; font-size: 18px; color: #7C7C7C; min-height: 115px;">${item?.desc}</span>` +
      `<a class="-dc-home-countries-explore more" href="${item?.link}">Explore <span class="material-icons">chevron_right</span></a>`;
    setContent({ color: item?.color, content: card });
  };

  const getBreakpintClass = () => {
    if (breakpoint["xxl"]) {
      return "largest";
    }

    if (breakpoint["xl"] && width > 1400) {
      return "xl";
    }

    if (breakpoint["xl"] && width <= 1400) {
      return "large";
    }

    if (breakpoint["md"] || breakpoint["lg"]) {
      return "medium";
    }

    if (breakpoint["xs"] || breakpoint["sm"]) {
      return "small";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    openCard();
  }, [selected]);

  const list = [
    {
      id: "brown",
      value: "15.9%",
      desc: "Proportion of Women in Managerial Positions - 13th ICLS [Female] in India (2022)",
      link: `${routeDataCommonsConstants.SEARCH}?q=Women in managerial positions in India`,
      color: "#AA5D00",
    },
    {
      id: "blue",
      value: "36.3%",
      desc: "Proportion of Population Living in Multidimensional Poverty in Mexico (2022)",
      link: `${routeDataCommonsConstants.SEARCH}?q=poverty+in+mexico`,
      color: "#1C4969",
    },
    {
      id: "red",
      value: "34M",
      desc: "Undernourished People in Nigeria (2021)",
      link: `${routeDataCommonsConstants.SEARCH}?q=Hunger+in+Nigeria`,
      color: "#A01D43",
    },
    {
      id: "green",
      value: "67.7%",
      desc: "Renewable Energy Share in the Total Final Energy Consumption in Kenya (2021)",
      link: `${routeDataCommonsConstants.SEARCH}?q=How has access to electricity improved in Kenya?`,
      color: "#509E3E",
    },
    {
      id: "purple",
      value: "97.9%",
      desc: "Proportion of Population Using Safely Managed Drinking Water Services, by Urban/Rural [Urban] in China (2022)",
      link: `${routeDataCommonsConstants.SEARCH}?q=Access+to+clean+drinking+water+in+China`,
      color: "#8E52A0",
    },
  ];

  return (
    <>
      <MapContainer className="container">
        <Header>
          <Row>
            <BlockTitle style={{ textAlign: "left" }} xs={20}>
              Countries / Areas
            </BlockTitle>
            <Col style={{ textAlign: "right" }} xs={4}>
              <Link className="-dc-home-thematic-areas-see-all" to={`${routeDataCommonsConstants.SDG_HOME}`}>
                See all
                <span className="material-icons">chevron_right</span>
              </Link>
            </Col>
          </Row>
        </Header>

        <Row gutter={32}>
          <Col xs={24} lg={8}>
            <Box props={{ color: content.color, content: content.content }} paddingClass={`.4rem`} />
          </Col>
          <Col xs={24} lg={16}>
            <MapArea>
              <ul className={getBreakpintClass()}>
                {list.map((v, i) => {
                  return (
                    <li key={i} onClick={() => setSelected(v.id)} className={selected == v.id ? "selected" : ""} id={v.id}>
                      &nbsp;
                    </li>
                  );
                })}
              </ul>
              <img src="./images/datacommons/home-map.svg" />
            </MapArea>
          </Col>
        </Row>
        <DescCard>
          <Link className="-dc-home-countries-explore-more" to={`${routeDataCommonsConstants.SDG_HOME}`}>
            Explore More
          </Link>
        </DescCard>
      </MapContainer>
    </>
  );
};
