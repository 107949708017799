import MiniSearch from "minisearch";
import { useContext, useEffect, useRef, useState } from "react";
import { ArticleResponse } from "../../../../hooks/useArticles";
import DataCommonsClient from "../../utils/DataCommonsClient";
import { WEB_API_ENDPOINT } from "../../utils/constants";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import AreaTable from "./AreaTable";
import ExploreSubTheme from "./ExploreSubTheme";
import SubThemeCarousel from "./SubThemeCarousel";
import { FilterParams, OpenKeysContext } from "./ThematicAreaView";

const ThematicAreaWrapper: React.FC = ({ area, contentPage, filterParams, store, opened, thematicAreas, partnerList }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [statVars, setStatVars] = useState<string[]>([]);
  const [isMiniSearchReady, setIsMiniSearchReady] = useState<boolean>(false);
  const [allFlatData, setAllFlatData] = useState([]);
  const [filtredData, setFiltredData] = useState(null);
  const [indicatorsCount, setIndicatorsCount] = useState(0);
  const { openKeys, setOpenKeys } = useContext(OpenKeysContext);
  const miniSearchRef = useRef(
    new MiniSearch({
      fields: ["label", "type", "indicator_name"],
      storeFields: ["key", "label", "indicator_name", "parentId"],
      idField: "id",
    })
  );

  useEffect(() => {
    let statVarsTmp: string[] = [];
    if (area.key) {
      setLoaded(false);
      getFulFill([area.key], filterParams.location).then((res) => {
        if (res.config?.categories?.[0]?.statVarSpec) {
          statVarsTmp = Object.keys(res.config.categories[0].statVarSpec);
        }
        setStatVars(statVarsTmp);
        setLoaded(true);
      });
    }
  }, [filterParams.location]);

  useEffect(() => {
    if (!isMiniSearchReady && area) {
      let tmpArea = !area?.children
        ? area
        : {
            ...area,
            children: area.children.filter((a) => !(a.id + "").includes("-topic")),
          };
      let f = flattenNestedData([tmpArea]);
      setAllFlatData(f);
      miniSearchRef.current.addAll(f);
      setIsMiniSearchReady(true);
    }
  }, [area]);

  useEffect(() => {
    if (filterParams && allFlatData.length && statVars.length) {
      nestedSearch({ ...filterParams, statVars });
    }
  }, [filterParams, allFlatData, statVars]);

  const nestedSearch = (params: FilterParams) => {
    let queFiltred;
    if (params.que) {
      queFiltred = miniSearchRef.current.search(params.que, {
        prefix: true,
        fuzzy: 0.2,
      });
      // let parentTop = queFiltred.filter((a) => !a.parentId)?.[0]?.score
      let childrenTop = queFiltred.filter((a) => a.indicator_name)?.[0]?.score;
      queFiltred = queFiltred.filter((a) => a.score * 3 > (a.indicator_name ? childrenTop : 1));
    }

    let { count, results } = findAllNestedMatches(
      area.children,
      params,
      queFiltred?.map((a) => a.label || a.indicator_name)
    );
    setFiltredData(results);
    setIndicatorsCount(count);
    if (
      params.que //|| params.location?.[0] != "Earth"
    )
      expandKeys(results);
    // setLoaded(true);
  };

  const findAllNestedMatches = (items, searchTerm, queFiltred) => {
    if (!items) return false;
    let count = 0;
    let partnerCodes = partnerList?.[0].children?.map((a) => a.value);
    const results = items
      .filter((item) => {
        // ( searchTerm.location.includes('Earth'))
        let partnersCheck =
          !item.agency ||
          ((!searchTerm?.partners?.length || searchTerm.partners.includes("all") || searchTerm.partners.includes(item.agency)) &&
            partnerCodes.includes(item.agency));

        let locationCheck =
          !item.indicator_codes ||
          searchTerm.location.includes("Earth") ||
          !searchTerm?.location?.length ||
          !searchTerm?.statVars?.length ||
          searchTerm?.statVars.some((i) => item?.indicator_codes.includes(i));

        //   let topicCheck = searchTerm.thematics[0] != '0' ? (searchTerm.thematics.find(a=>a.includes(item.id)||(item.id+'').includes(a))) : true
        return partnersCheck && locationCheck;
      })
      .map((item) => {
        let label = item.label || item.indicator_name;
        if (item.children) {
          // Recursively search children
          const childMatches = findAllNestedMatches(item.children, searchTerm, queFiltred);
          if (childMatches.results.length > 0) {
            count += childMatches.count; // Add count from child matches
            return { ...item, children: childMatches.results || [] };
          }
        } else if (!queFiltred || queFiltred?.includes(label)) {
          // else if ( label.toLowerCase().includes(searchTerm.que.toLowerCase()) ) {
          count += 1; // Increment count for last-level match
          return { ...item };
        }
        return null; // No match
      })
      .filter(Boolean);

    return { results, count };
  };

  const flattenNestedData = (data, parentId = null) => {
    const flatData = [];
    if (!data || !data.length) return [];
    data.forEach((item) => {
      flatData.push({
        ...item,
        parentId, // Keep a reference to the parent
      });

      if (item.children) {
        flatData.push(...flattenNestedData(item.children, item.id));
      }
    });

    return flatData;
  };

  async function getFulFill(variables: string[], entities: string[]) {
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });

    if (!entities.length) return false;

    try {
      // Get all SDGs articles
      const response: ArticleResponse = await client.fulfill({
        variables,
        dc: "undata",
        entities: entities || filterParams.location,
      });
      return response;
    } catch (error) {
      console.error("Error fetching thematics:", error);
    }
    return false;
  }

  const collectIds = (items) => {
    return items.reduce((acc, item) => {
      const childIds = item.children?.map((child) => child.id) || [];
      return [...acc, item.id, ...childIds];
    }, []);
  };

  const expandKeys = (data) => {
    let allKeys = data?.length
      ? // collectIds(data) //?
        data.map((record) => record.id)
      : [];
    setOpenKeys([...new Set([...openKeys, ...allKeys, area.id])]);
    // setOpenKeys([...new Set(allKeys.concat(openKeys))])
  };

  const findItem = (array, code) => {
    let result;
    array.some((child) => {
      return ((Array.isArray(code) ? code.includes(child.id + "") : child.id == code) && (result = child)) || (result = findItem(child.children || [], code));
    });
    return result;
  };

  if (!loaded)
    return (
      <ContentCard>
        <Spinner />
      </ContentCard>
    );

  if (!filtredData?.length && !!filterParams.que) {
    return <></>;
  }

  if (contentPage.page == "explore") {
    if (!filtredData) return <></>;

    let data = findItem(filtredData, contentPage.data.id || area.id);
    return data ? <ExploreSubTheme partnerList={partnerList} filterParams={filterParams} data={data} areaId={area.id} /> : <></>;
  }

  if (contentPage.page == "carousel") {
    if (!filtredData) return <></>;

    let data = findItem(filtredData, contentPage.data.id);
    return data ? <SubThemeCarousel partnerList={partnerList} filterParams={filterParams} data={data} /> : <></>;
  }

  return (
    <AreaTable
      filterParams={{ ...filterParams, statVars }}
      area={area}
      store={store}
      opened={opened}
      filtredData={filtredData}
      indicatorsCount={indicatorsCount}
    />
  );
};

export default ThematicAreaWrapper;
