import { Menu } from "antd";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../helper/Common/RoutePathConstants";
import { isMobile } from "../../../script/Commonfunctions";

const getMenuMode = () => {
  if (isMobile()) {
    return "inline";
  } else {
    return "horizontal";
  }
};

export default class TopNavigation extends Component {
  // all submenu keys of first level
  rootSubmenuKeys = [""];
  constructor(props) {
    super(props);
  }
  state = {
    current: "home",
  };

  handleClick = (e) => {
    if (e.key !== "help" && !this.isHelpSubmenuItem(e.key)) {
      this.setState({ current: e.key });
    }
  };

  isHelpSubmenuItem = (key) => {
    return ["faq", "documentation", "support", "feedback", "follow"].includes(key);
  };

  componentDidMount() {
    const history = createBrowserHistory();
    let route = history.location.pathname;

    if (route === routeDataCommonsConstants.COUNTRY) {
      this.setState({ current: "countries" });
    } else if (route === routeDataCommonsConstants.SDG_HOME || route.includes("goals")) {
      this.setState({ current: "landing" });
    } else if (route === routeDataCommonsConstants.SEARCH) {
      this.setState({ current: "search" });
    } else if (route.includes("areas")) {
      this.setState({ current: "areas" });
    } else if (route.includes("partners")) {
      this.setState({ current: "partners" });
    } else {
      this.setState({ current: "home" });
    }
  }


  render() {
    const { current } = this.state;

    return (
      <MainMenu>
        <Menu
          onClick={this.handleClick}
          selectedKeys={this.isHelpSubmenuItem(current) ? [] : [current]}
          mode={getMenuMode()}
          theme="dark"
        >
          <Menu.Item key="home">
            <NavLink
              to={`${routeDataCommonsConstants.HOME_PATH}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Home
            </NavLink>
          </Menu.Item>
          {/*<Menu.Item key="countries">
            <NavLink
              to={`${routeDataCommonsConstants.COUNTRY}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Countries / Areas
            </NavLink>
          </Menu.Item>*/}
          <Menu.Item key="areas">
            <NavLink
              to={`${routeDataCommonsConstants.THEMATIC_AREAS}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Thematic Areas
            </NavLink>
          </Menu.Item>
          <Menu.Item key="landing">
            <NavLink
              to={`${routeDataCommonsConstants.SDG_HOME}`}
              className="-dc-nav-link nav-link"
              exact
            >
              SDGs
            </NavLink>
          </Menu.Item>

          <Menu.Item key='partners'>
            <NavLink
              to={`${routeDataCommonsConstants.PARTNER}`}
              className='-dc-nav-link nav-link'
            >
              Data Partners
            </NavLink>
          </Menu.Item>

          <Menu.Item key="search">
            <NavLink
              to={`${routeDataCommonsConstants.SEARCH}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Search
            </NavLink>
          </Menu.Item>


          <Menu.SubMenu
            key="help"
            title="Help"
            popupOffset={[-100, 0]}
            style={{ marginLeft: "auto", borderLeft: "none", borderLeft: "1px solid #fff" }}
            popupClassName="-dc-nav-link nav-link custom-dropdown"
          >
            <Menu.Item key="faq">
              <a
                href="./undatacommons/docs#/about-un-data"
                rel="noopener noreferrer"
                target="_blank"
              >
                Help Guide
              </a>
            </Menu.Item>


            <Menu.Item key="feedback">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeAjzArkG-LYDDrdTQ6D1XH1ISWMhlbWE6NRzPt8dYGh_1Ufw/viewform"
                rel="noopener noreferrer"
                target="_blank"
              >
                Feedback
              </a>
            </Menu.Item>
            <Menu.Item key="follow">
              <a
                href="./undatacommons/docs/#/follow-us"
                rel="noopener noreferrer"
                target="_blank"
              >
                Follow Us
              </a>
            </Menu.Item>
            <Menu.Item key="blog">
              <a
                href={`${routeDataCommonsConstants.BLOG}`}
              >Blog
              </a>
            </Menu.Item>

          </Menu.SubMenu>
        </Menu>
      </MainMenu>
    );
  }
}

const MainMenu = styled.div`
  .ant-menu-item-selected a {
    color: #4d4d4d !important;
    background-color: #F2F2F2;
  }

  @media (max-width: 825px) {
    .ant-menu-item-selected a {
      color: #f2f2f2 !important;
      background-color: #4d4d4d !important;
    },
    .ant-menu-sub > li > span{
      padding-left: 1.5rem;
    }
  }

  .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-submenu-title {
   padding-right: 14px !important;
  }
  .custom-dropdown ul.ant-menu.ant-menu-sub.ant-menu-vertical{
      border-radius: 0 !important;
  }
`