/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ExportOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Grid, Row } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useStoreState } from "../../../state";
import {
  QUERY_PARAM_VARIABLE
} from "../../../utils/constants";
import { Spinner } from "../../countries/CountriesContent";
import DataStories from "../../goals/DataStories";
import { ContentCard, ContentCardBody, ContentCardHeader } from "../components";

const { useBreakpoint } = Grid;
interface StoryNode {
  name: string;
  dcid: string;
}
export interface Article {
  name?: string;
  text?: string;
  tags?: StoryNode[];
  source?: string;
  sourceUrl?: string;
  createdAt?: string;
  image?: string;
  caption?: string;
  credit?: string;
  storyNodes?: StoryNode[];
  footnote?: string;
  note?: string;
  imageSource?: string;
}

export const sdgColors = {
  SDG1: "#E5243B",
  SDG2: "#DDA63A",
  SDG3: "#4C9F38",
  SDG4: "#C5192D",
  SDG5: "#FF3A21",
  SDG6: "#26BDE2",
  SDG7: "#FCC30B",
  SDG8: "#A21942",
  SDG9: "#FD6925",
  SDG10: "#DD1367",
  SDG11: "#FD9D24",
  SDG12: "#BF8B2E",
  SDG13: "#3F7E44",
  SDG14: "#0A97D9",
  SDG15: "#56C02B",
  SDG16: "#00689D",
  SDG17: "#19486A",
};
const GoalOverview: React.FC<{
  goalNumber: number;
  showExploreLink?: boolean;
  showDataStories?: boolean;
  goalData?: Article[];
}> = ({ goalNumber, showExploreLink, goalData, showDataStories }) => {
  const goalIndex = goalNumber - 1;
  const goalSummary = useStoreState(
    (s) => s.goalSummaries.byGoal[`${goalNumber}`]
  );
  const rootTopic = useStoreState((s) => s.rootTopics[goalIndex]);
  const location = useLocation();
  const [content, setContent] = useState<Article>({});
  const [dataStories, setDataStories] = useState<Article[]>([])
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (goalData) {
      setContent(Object.keys(goalData).filter(a=>a.includes('/highlights')).map(a=>goalData[a])[0]);
      if(showDataStories)
      setDataStories(Object.keys(goalData).filter(a=>!a.includes('/highlights')).map(a=>goalData[a]) as Article[])
    } 
  }, [goalNumber, goalData]);

  if (!goalSummary || !rootTopic) {
    return null;
  }

  const searchParams = new URLSearchParams(location.search);
  searchParams.set(QUERY_PARAM_VARIABLE, rootTopic.topicDcid);
  const readMoreUrl = `https://unstats.un.org/sdgs/report/2024/Goal-${goalNumber.toString().padStart(2, '0')}/`;
  const exploreUrl = location.pathname + "?" + searchParams.toString();
  return (<>
    <GoalContentCard
      className={`-dc-goal-overview -dc-goal-overview-${goalNumber}`}
    >
      <Row className="with-pattern">
        <Col md={24} lg={24}>
          <GoalCardHeader $xs={breakpoint.xs}>
            <img src={rootTopic.iconUrl} />
            <span to={exploreUrl}>
              <h3>
                {/* {goalNumber}:  */}
                {rootTopic.name}
              </h3>
            </span>
          </GoalCardHeader>
          {content?.text ? (
            <ContentCardBody>
              <Row>
                <StyledCol lg={24}>
                  <GoalText $color={sdgColors[`SDG${goalNumber}`]}>
                    {content?.text
                      ?.replace(/^-/, "")
                      .split(`\\n-`)
                      .map((headline, i) => (
                        <li key={i}>{headline.replace('\\n','')}</li>
                      ))}
                  </GoalText>
                </StyledCol>
              </Row>
              {showExploreLink && (
                <p>
                  <ExploreLink href={readMoreUrl} target="_blank">
                    Read more 
                    {/* <SRightOutlined /> */}
                    <ExportOutlined/>
                  </ExploreLink>

                </p>
              )}
            </ContentCardBody>
          ) : (
            <Spinner />
          )}
        </Col>
        <NewsCol md={24} lg={24} $color={sdgColors[`SDG${goalNumber}`]}>
          {content?.image ? (
            <><div className="image-wrapper" style={{backgroundImage:`url(${content.image})`}}>
                <img src={content.image} alt="" />
              </div>
              <div className="text-wrapper">
                <h3>{content.caption}</h3>
                <p>{content.credit}</p>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </NewsCol>
      </Row>
      {!!dataStories?.length && <DataStories goalName={rootTopic.name} goalNumber={goalNumber} color={sdgColors[`SDG${goalNumber}`]} content={dataStories}/>}
    </GoalContentCard>
          
          </>

  );
};

export default GoalOverview;

const GoalContentCard = styled(ContentCard)`
  padding: 0 0;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  .with-pattern {
    position: relative;
  }
  .with-pattern:before {
    content: " ";
    display: block;
    width: 6rem;
    height: calc(100% - 96px);
    position: absolute;
    background: repeating-linear-gradient(45deg, #7D7D7D99, #7D7D7D99 4px, #fff 4px, #fff 8px);
    left: 0;
    top: 96px;
    z-index: 2;
    opacity: .2;
  }
  @media(max-width: 992px) {
    .with-pattern:before {
      height: calc(100% - 72px);
      top: 72px;
      width: 1rem;
    }
  }
`

const GoalText = styled.ul<{
  $color?: string;
}>`
  ${({ $color }) => `
  padding-left: 1rem;
  margin-bottom: 2rem;
  font-size: 16px;
  li {
    padding-bottom:.5rem;
    color: #000;
  }
  li::marker {
    color:${$color};
    font-size: 1.5em;
  }
`}
`;

export const NewsCol = styled(Col)<{
  $color?: string;
}>`
  ${({ $color }) => `
  background: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  > div.text-wrapper {
    background: ${$color}10;
    height: 100%;
    width: calc(100%-616px);
    padding: 2.5rem 2.5rem 2.5rem 5rem;
    position:relative;
    :before{
      content: " ";
      background: ${$color};
      width: .5rem;
      height: calc(100% - 4rem);
      display: inline-block;
      position: absolute;
      top: 2rem;
      left: 2.5rem;
    }
    h3 {
      font-size: 14px;
      font-weight: 400; 
    }
    p {
      padding-top: 1rem;
      font-size: 14px;
    }
  }
  .image-wrapper {
    width: 60%;
    max-width: 600px;
    min-width: 600px;
    height: 0;
    overflow: hidden;
    padding-top: 315px;
    background-size: cover;
    img {
      width: 100%;
    }
  }
  @media(max-width: 1200px){
    > div.text-wrapper {
      width: calc(100%-470px);
    }
    .image-wrapper {
      min-width: 55%;
      padding-top: 35%;
    }
  }
  @media(min-width: 2000px){
    > div.text-wrapper {
      width: calc(100% - 616px);
    }
    .image-wrapper {
      width: 616px;
      min-width: 616px;
      padding-top: 320px;
    }
  }
  @media(max-width: 992px){
    padding-left: 0;
    flex-wrap: wrap;
    display:inline-block;
    border-radius: 0;
    .image-wrapper {
      width: 100%;
      padding-top: 50%;
      border-radius: 0;
    }
    > div.text-wrapper {
     width: 100%;
      height: auto;
      min-height:240px;
    }
  }
`}
`;

const GoalCardHeader = styled(ContentCardHeader)<{
  $xs?: boolean;
}>`
  background: #2B2B2B;
  padding: 0 16px 16px;
  border-radius: 8px 8px 0 0;
  h3{color:#fff;}
  margin-bottom: 0;
  ${(p) =>
    p.$xs
      ? `
      img {
        height: 3.5rem;
        margin-right: 1rem;
        width: 3.5rem;
      }
      h3 {
        font-size: 1.25rem;
      }
    `
      : ""}
`;

const StyledCol = styled(Col)`
  flex-grow: 1;
`;
const SRightOutlined = styled(RightOutlined)`
  margin-left: 4px;
  font-size: 12px;
`;

const ExploreLink = styled.a`
  display: flex;
  align-items: center;
  border: solid lightgrey 1px;
  width: fit-content;
  border-radius: 20px;
  padding: 6px 18px 6px 22px;
  color: #202020!important;
  float:right;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 14px;
  &:hover{
    color: #027dbc!important;
  border-color: lightblue;
  }
  .anticon {
    margin-left: 0.5rem;
  }
`;