import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import AppLayoutContent from "../shared/AppLayoutContent";

const MarkdownWrapper = styled.div`
  h1 {
    margin-bottom: 2rem;
  }

  p {
    line-height: 1.6;
  }
`;

const Blog: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/blog/undata_blog_01.md`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the Markdown file.");
        }
        return response.text();
      })
      .then((text) => setContent(text))
      .catch((error) => console.error("Error loading the Markdown file:", error));
  }, []);

  return (
    <AppLayoutContent>
      <Layout className="container" style={{ backgroundColor: "transparent", padding: "2rem" }}>
        <MarkdownWrapper>
          <ReactMarkdown>{content}</ReactMarkdown>
        </MarkdownWrapper>
      </Layout>
    </AppLayoutContent>
  );
};

export default Blog;
