import { ConfigProvider } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import Rollbar from 'rollbar';
import App from "./App";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

// Initialize Rollbar
const rollbar = new Rollbar({
  accessToken: '144091436ca04e5aae8d55b7631f0965',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'testenv',
});

ReactDOM.render(
  <ConfigProvider direction={window.localStorage.getItem("dir")}>
    <App />
  </ConfigProvider>,
  rootElement
);
