import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ROOT_TOPIC } from "../../utils/constants";
import ChartCarousel from "../areas/ChartCarousel";
import { BackButton, IndicatorHeader, SubAreacard } from "../areas/ThematicAreaView";
import { Divider } from "../shared/components";
import { Article } from "../shared/goals/GoalOverview";
import { StoryContent } from "../shared/goals/IndicatorOverview";
import { StoryBreadcrumbs, StoryHeader } from "./DataStories";
import { TargetBadge } from "./ExploreIndicator";
import InfographicView from "./InfographicView";

const IndicatorCarousel:React.FC<{
    topics: [];
    placeDcid: string;
    name: string;
    codes: string[];
    setCarouselProps: ()=>void;
    index: number;
    infographics: []
    dataStories: Article[];
    color: string;
    goalNumber: string;
    goalName: string;
}> = ({topics, placeDcid, name, codes, setCarouselProps, index, infographics, dataStories, color, goalNumber, goalName}) => {
    const history = useHistory();
    const goBack = () => {
        setCarouselProps({})
        // history.goBack
    }
    let indicatorNumber = topics?.[0]?.split('_')[1]
    return <CarouselContainer>
    <CarouselHeader>
          <Row>
            {name && (
              <Col flex="0 1">
                <BackButton size="small" onClick={goBack}>
                  <ArrowLeftOutlined />
                </BackButton>
              </Col>
            )}
            <Col flex="1 1">
              <span>
                <TargetBadge className={`-dc-target-header -dc-target-header-${indicatorNumber}`} color={color}>
                  {indicatorNumber}
                </TargetBadge>
                {name.replace(indicatorNumber,'')}
              </span>
            </Col>
          </Row>
        </CarouselHeader>
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }}>
        <ChartCarousel
            topics={topics} 
            placeDcid="Earth" 
            name={name} 
            codes={codes} 
            limit={200} 
            initialSlide={index}
            exploreLink={`?v=${ROOT_TOPIC}_${indicatorNumber}`}
        >
            {infographics && infographics.find(a=>a.key == indicatorNumber) && !!infographics.length && <InfographicView id={indicatorNumber}/>}
            {dataStories && Object.values(dataStories).length && Object.values(dataStories).map((story, i) => {
              let breadcrumbs = story?.tags?.sort((a, b) => {
                const aIncludes = a.dcid.includes(`_${goalNumber}`);
                const bIncludes = b.dcid.includes(`_${goalNumber}`);
                
                if (aIncludes && !bIncludes) return -1;
                if (!aIncludes && bIncludes) return 1;
                return 0;
            }).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
              // let breadcrumbs = story?.tags?.filter(a=>a.dcid.includes(`_${goalNumber}`)).map(a=>a.dcid.replace(ROOT_TOPIC+'_',''))
              return <StoryView $color={color} header={story.name} key={i}>
                <StoryHeader color={color}>
                    {breadcrumbs?.length && <StoryBreadcrumbs breadcrumbs={breadcrumbs} goalName={goalName}/>}
                    <h4>{story.name}</h4>
                    <Divider dataColor={color}/>
                </StoryHeader>
                <StoryContent content={story}/>
            </StoryView>})}
        </ChartCarousel>
        </SubAreacard>
    </CarouselContainer>
}
export default IndicatorCarousel

const CarouselHeader = styled(IndicatorHeader)`
    margin: 0;
`
export const StoryView = styled.div`
overflow: hidden;
    >div:first-child h4 {
        padding-left:16px;
    }
`
const CarouselContainer = styled.div`
    margin-bottom: 20px;
`