import { Layout } from "antd";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { routePathConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state/index";
import { EARTH_PLACE_DCID, QUERY_PARAM_VARIABLE, ROOT_TOPIC } from "../../utils/constants";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import GoalContent from "./GoalContent";
export const OpenKeysContext = createContext([]);

const OpenKeysProvider = ({ children }) => {
  const [openKeys, setOpenKeys] = useState(null);
  const [showOverview, setShowOverview] = useState<boolean>(true);
  const [backUrl, setBackUrl] = useState<string|null>(null)
  return (
    <OpenKeysContext.Provider value={{ openKeys, setOpenKeys, showOverview, setShowOverview, backUrl, setBackUrl }}>
      {children}
    </OpenKeysContext.Provider>
  );
};

const Goals: React.FC = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [loaded, setLoaded] = useState(false)
  const variableDcid = useMemo(() => searchParams.get(QUERY_PARAM_VARIABLE) || ROOT_TOPIC, [searchParams]);


  const setVariableDcid = useCallback(
    (variableDcid: string) => {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set(QUERY_PARAM_VARIABLE, variableDcid);
      history.push(`${location.pathname}?${newSearchParams.toString()}`);
    },
    [location, history]
  );

  const topics = useStoreState((s) => [variableDcid].filter((dcid) => dcid in s.topics.byDcid).map((dcid) => s.topics.byDcid[dcid]));
  

  const parentVariables = useStoreState((s) => {
    const parentDcids: string[] = [];
    if (topics.length !== 1) {
      return [];
    }
    let currentVariableDcid = [variableDcid][0];
    const BREADCRUMB_LIMIT = 10;
    let breadcrumbIndex = 0;
    while (currentVariableDcid !== ROOT_TOPIC) {
      breadcrumbIndex++;
      if (breadcrumbIndex > BREADCRUMB_LIMIT) {
        break;
      }
      if (!(currentVariableDcid in s.topics.byDcid)) {
        break;
      }
      currentVariableDcid = s.topics.byDcid[currentVariableDcid].parentDcids[0];
      parentDcids.unshift(currentVariableDcid);
    }

    return parentDcids.map((parentDcid) => {
      const num: string = parentDcid.split('_')[1];
      let name: string = s.topics.byDcid[parentDcid].name;
      if (parentDcid.includes('.')) {
        name = (parentDcid.split('.').length > 2 ? 'Explore Indicator ' : 'Target ') + num;
      }
      return parentDcid.includes('.') ? { ...s.topics.byDcid[parentDcid], name } : s.topics.byDcid[parentDcid];
    });
  });

  useEffect(()=>{
    if(topics.length)
    setLoaded(true)
  }, [topics])

  const num = topics[0]?.dcid?.split('_')[1];
  const lvl = num ? num.split('.').length : 0;
  const { DATA_COMMONS } = routePathConstants;

  return (
    <AppLayoutContent id="top" style={{ display: "flex", flexDirection: "column" }}>
      <Layout className="container" style={{ backgroundColor: "transparent" }}>
        {loaded && <AppBreadcrumb
          items={[
            { name: 'SDGs', to: `${DATA_COMMONS}sdgs` },
            ...parentVariables
              .filter((v) => v)
              .map((v) => {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set(QUERY_PARAM_VARIABLE, v.dcid);
                return { to: `${location.pathname}?${searchParams.toString()}`, name: v.name };
              }),
          ]}
        >
          {lvl === 3 ? `Explore Indicator ${num}` : ''}
          {lvl === 2 ? `Target ${num}` : ''}
          {lvl < 2 && topics.length === 1 ? topics[0].name : ''}
        </AppBreadcrumb>
}
      </Layout>
      <OpenKeysProvider>
      <Layout style={{ height: "100%", flexGrow: 1, flexDirection: "row" }}>
        {/* <AppSidebar placeDcid="" variableDcid={variableDcid} setVariableDcid={setVariableDcid} showThematicAreas={false} /> */}
        <Layout style={{ overflow: "auto" }}>
        {loaded && 
          <GoalContent hidePlaceSearch={true} variableDcids={[variableDcid]} placeDcids={[EARTH_PLACE_DCID]} setPlaceDcid={() => {}} />
            }
          {/* {loaded && <CountriesContent hidePlaceSearch={true} variableDcids={[variableDcid]} placeDcids={[EARTH_PLACE_DCID]} setPlaceDcid={() => {}} />
          } */}
        </Layout>
      </Layout>
      </OpenKeysProvider>
    </AppLayoutContent>
  );
});

export default Goals;
