import {
  AppstoreOutlined,
  CaretRightOutlined,
  DownOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Badge, Col, Collapse, Row, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useFetchArticles, {
  GroupedArticles,
} from "../../../../hooks/useArticles";
import { Topic } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { FulfillResponse } from "../../utils/types";
import ChartCarousel from "../areas/ChartCarousel";
import { FilterParams, GridHeader } from "../areas/ThematicAreaView";
import { getGoalTargetIndicator, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import { sdgColors } from "../shared/goals/GoalOverview";
import GoalTableOverview from "./GoalTableOverview";

const { Panel } = Collapse;
interface DataType {
  indicator_name: string;
  indicator_codes: string[];
}

const GoalTable: React.FC<{
  store: object | undefined;
  filterParams: FilterParams | undefined;
  columns: ColumnsType<DataType>;
  area: Topic;
  opened?: boolean | number;
  placeDcids?: string[];
  localFulfillResponse?: FulfillResponse;
  setCarouselProps?: object | undefined;
  varToTopics: object;
}> = ({
  columns,
  opened,
  filterParams,
  store,
  area,
  placeDcids,
  localFulfillResponse,
  setCarouselProps,
  varToTopics,
}) => {
  const { fetchArticles } = useFetchArticles();
  const [expandedRowKeys, setExpandedRowKeys] = useState<[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [toggleCharts, setToggleCharts] = useState<boolean>(false);
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  // const [indicators, setIndicators] = useState([]);
  const [dataStories, setDataStories] = useState<
    false | GroupedArticles | undefined | null
  >(null);
  const isMounted = useRef(true);
  const [showOverview, setShowOverview] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState(null);
  const [nestedData, setNestedData] = useState(null);
  const [filtredData, setFiltredData] = useState(null);
  const [indicatorsCount, setIndicatorsCount] = useState(0);

  const goal = area?.key.split("_")[1];
  
    // console.log(localFulfillResponse.config.categories, 'localFulfillResponse')

  useEffect(() => {
    if(!localFulfillResponse?.config?.categories) return;
    setLoaded(false)
    isMounted.current = true;
    let dcids = area.children
      .map((a) => {
        return a.children.map((b) => b.key);
      })
      .flat(1);
      
    const topics = localFulfillResponse?.config.categories?.[0].blocks.map((a) => {
        return {
          label: a.title,
          key: a.columns[0].tiles[0].statVarKey,
          type: 'indicator'
        };
    });

    // console.log(dcids, filterParams?.location, filterParams?.location?.length)

    if(!topics?.length) {console.log('topics missed'); return}
    if(filterParams?.location?.length == 1 && filterParams?.location.includes(EARTH_PLACE_DCID)){
    fetchArticles(dcids || [`${area?.key}`]).then((resp) => {
        if (isMounted.current) {
          setDataStories(resp);
          let d = wrapNestedData({infographics, dataStories: resp, indicators:area?.children, topics, varToTopics })
          setNestedData(d.data)
          setIndicatorsCount(d.count)
          setFiltredData(d.data)
          setLoaded(true)
        }
      });
    } else {
      let d = wrapNestedData({infographics:[], dataStories:[], indicators:area?.children, topics, varToTopics })
      setNestedData(d.data)
      setIndicatorsCount(d.count)
      setFiltredData(d.data)
      setLoaded(true) 
    }
    return () => {
      isMounted.current = false;
    };
  }, [localFulfillResponse, area, fetchArticles]);


  useEffect(() => {
    if(loaded)
    if (filterParams) nestedSearch(filterParams);
  }, [filterParams?.que]);

  const wrapNestedData = ({infographics, dataStories, indicators, topics, varToTopics }) => {

    let count = 0
    let d = indicators.map((a) => {
      return {
        ...a,
        children: a.children.map((b) => {
          let childs = [];
          // Infographics
          childs = childs.concat(infographics);
          // data stories
          if (dataStories?.[b.key]) {
            childs = childs.concat(
              Object.keys(dataStories[b.key]).map((k) => ({
                ...dataStories[b.key][k],
                key: k,
                title: dataStories[b.key][k].name,
                type: 'dataStory'
              }))
            );
          }
          // topics
          if (topics?.length){
            // console.log(topics, 'local tpoics')
            childs = childs.concat(
              topics.filter((t) =>
                hasIntersection(t.key, varToTopics[b.key])
              )
            );
            }
          count+=childs.length
          return { ...b, children: childs };
        }),
      };
    });

    return {data:d, count}

  }

  const expandAll = () => {
    setToggleCharts(!toggleCharts);
    const allKeys = indicators?.length
      ? indicators.map((record) => record.key)
      : [];
    setExpandedRowKeys(allKeys);
  };

  const collapseAll = () => {
    setToggleCharts(!toggleCharts);
    setExpandedRowKeys([]);
  };

  const onExpand = (expanded, record) => {
    let keys = [];
    if (expanded) {
      keys = [...expandedRowKeys, record.key];
    } else {
      keys = expandedRowKeys?.length
        ? [...expandedRowKeys.filter((i, k) => i != record.key)]
        : [];
    }

    setExpandedRowKeys(keys);
  };

  const nestedSearch = (params: FilterParams) => {
    // setFilterParams(params)
    // if (filterParams?.que) {
        
      // const flat = flatIndicators([area], [], [], 0, area.key);
      // const filtered = flat?.filter((item) => {
      //   const indicator = item?.indicator_name?.toLowerCase() ?? "";
      //   let queCheck = indicator.includes(params.que);
      //   let partnersCheck = params?.partners?.length
      //     ? params.partners.includes("all")
      //       ? []
      //       : params.partners.includes(item.agency)
      //     : true;
      //   let locationCheck = params?.statVars?.length
      //     ? params.location.includes("Earth")
      //       ? []
      //       : params?.statVars.some((i) => item?.indicator_codes.includes(i))
      //     : true;

      //   return queCheck && locationCheck && partnersCheck; //&& themeCheck
      // });
      // setIndicators(sortIndicators(filtered));
      let {count, results} = findAllNestedMatches(nestedData, params?.que)
      setFiltredData(results)
      setIndicatorsCount(count)
      setLoaded(true);
    // }
  };

  const findAllNestedMatches = (items, searchTerm) => {
    let count = 0;
    const results = items
    .map(item => {

      let label = item.label || item.name || item.title

      if (item.children) {
        // Recursively search children
        const childMatches = findAllNestedMatches(item.children, searchTerm);
        if (childMatches.results.length > 0) {
          count += childMatches.count; // Add count from child matches
          return { ...item, children: childMatches.results||[] };
        }
      } else if (label.toLowerCase().includes(searchTerm.toLowerCase())) {
        count += 1; // Increment count for last-level match
        return { ...item };
      }
      return null; // No match
    })
    .filter(Boolean);
    
    


    return {results, count};
  };


  const onHeaderClick = (e) => {
    setShowOverview(!showOverview);
    e.preventDefault();
  };

  const onArrowClick = (key) => {
    setActiveKey(key);
  };

  if(!loaded) return <ContentCard><Spinner/></ContentCard>
  if(!filtredData?.length || indicatorsCount==0) return <></>

  return (
    <AreaCollapse
      collapsible="icon"
      onChange={(key) => onArrowClick(key)}
      activeKey={activeKey || (opened && [area?.key])}
      expandIcon={({ isActive }) => (
        <Tooltip placement="top" title={`Expand`}>
        <ExpandIcon className={`material-icons ${isActive && `expanded`}`}>
          chevron_right
        </ExpandIcon></Tooltip>
      )}
      defaultActiveKey={opened || showOverview ? [area?.key] : []}
    >
      <Panel
        key={area?.key}
        header={
          <PanelHeader
            url={`?v=${area.key}`}
            label={area?.label}
            icon={area?.icon}
            count={indicatorsCount}
            toggleCharts={toggleCharts}
            sortDesc={sortDesc}
            setSortDesc={() => setSortDesc(!sortDesc)}
            expandAll={expandAll}
            collapseAll={collapseAll}
            onHeaderClick={onHeaderClick}
          />
        }
      >
        {showOverview ? (
          <GoalTableOverview
            goal={goal}
            fulfillResponse={localFulfillResponse}
            placeDcids={filterParams?.location || [EARTH_PLACE_DCID]}
          />
        ) : (
          <NestedTable
            columns={columns}
            rowKey={"key"}
            dataSource={filtredData}
            dataStories={dataStories}
            expandedRowKeys={expandedRowKeys}
            onExpand={onExpand}
            localFulfillResponse={localFulfillResponse}
            placeDcids={placeDcids}
            varToTopics={varToTopics}
            setCarouselProps={setCarouselProps}
            goalName={area.label}
          />
        )}
      </Panel>
    </AreaCollapse>
  );
};

const infographics = [
  {
    label:
      "Proportion of population covered by social protection floors/systems, by sex, distinguishing children, unemployed persons, older persons, persons with disabilities, pregnant women, newborns, work-injury victims and the poor and the vulnerable",
    key: "info_1.1.1",
    type: "info",
    to: `?v=${ROOT_TOPIC}#infographic`,
  },
];

const hasIntersection = (arr1, arr2) => {
  const set1 = new Set(arr1);
  return arr2?.some((item) => set1.has(item));
};

const NestedTable = ({
  columns,
  showCarousel,
  dataStories,
  goalName,
  rowKey,
  dataSource,
  expandedRowKeys,
  onExpand,
  localFulfillResponse,
  placeDcids,
  varToTopics,
  setCarouselProps,
}) => {

  return (
    <>
      {dataSource.length &&
        dataSource.map((item) => {
          let stories = dataStories?.[item.key]
            ? Object.values(dataStories?.[item.key]).map((a, i) => ({
                ...a,
                label: a.name,
                key: item.key + i,
                type: "dataStory",
              }))
            : [];
          const [goal, target, indicator] = item?.key
            ? getGoalTargetIndicator(item.key)
            : [];
          let index =
            (indicator != "none" && indicator) ||
            (target != "none" && target) ||
            (goal != "none" && goal);

          let itemTopics =
            !item.type
              ? []
              : [
                  // ...infographics.map(a=>a.key),
                  // ...stories.map(a=>a.key),
                  ...varToTopics[rowKey]
                  // ...topics
                  //   .filter((a) =>
                  //     hasIntersection(a.key, varToTopics[item.key])
                  //   )
                    
                  //   .map((a, i) => ({
                  //     ...a,
                  //     to: `?v=${ROOT_TOPIC}_${indicator}#${i}`,
                  //   })),
                ];

          // console.log(itemTopics, varToTopics[item.key], infographics, stories, 'vamos')
          return (
            <GoalItem
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              key={item.key}
              items={dataSource}
            >
              <Panel
                header={
                  <TargetName>
                    <GoalBadge color={sdgColors["SDG" + goal]} count={index} />
                    <span>{item.label?.replace(index + ":", "")}</span>
                  </TargetName>
                }
              >
                {!!item.children?.length && !item.children[0].type && (
                  <NestedTable
                    columns={columns}
                    rowKey={item.key}
                    dataSource={item.children}
                    dataStories={dataStories}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onExpand}
                    localFulfillResponse={localFulfillResponse}
                    placeDcids={placeDcids}
                    varToTopics={varToTopics}
                    showCarousel={goal && target && indicator}
                    setCarouselProps={setCarouselProps}
                    goalName={goalName}
                  />
                )}
                {showCarousel && !!item.children?.length && (
                  <div>
                    {item.children.map((a, i) => {
                      let codes = varToTopics[item.key]
                      return (
                        <IndicatorLink
                          onClick={() =>
                            setCarouselProps({
                              topics: [ROOT_TOPIC + "_" + indicator],
                              placeDcid: "Earth",
                              name:
                                index +
                                " " +
                                item.label?.replace(index + ":", ""), //a.label,
                              codes: codes,
                              to: a.to,
                              index: i,
                              infographics,
                              dataStories: dataStories && Object.keys(dataStories)?.length ? 
                                dataStories[ROOT_TOPIC + "_" + indicator] : {},
                              color: sdgColors["SDG" + goal],
                              goalName: goalName.replace(goal + ": ", ""),
                              goalNumber: goal,
                            })
                          }
                          color={sdgColors["SDG" + goal]}
                          to={a.to}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                          key={item.key + i}
                        >
                          <Panel
                            header={
                              <TargetName>
                                {/* <GoalBadge
                                  color={sdgColors["SDG" + goal]}
                                  count={index}
                                /> */}
                                <Bullet color={sdgColors["SDG" + goal]}/>
                                <span>
                                  {a.label || a.title}
                                  {a.type == "info" && (<>
                                    <GoalBadge
                                      style={{ marginLeft: "6px" }}
                                      color={sdgColors["SDG" + goal]}
                                      count={`Infographic`}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" fill="#323232"/>
                                      <path d="M9 12H7V17H9V12Z" fill="#323232"/>
                                      <path d="M17 7H15V17H17V7Z" fill="#323232"/>
                                      <path d="M13 14H11V17H13V14Z" fill="#323232"/>
                                      <path d="M13 10H11V12H13V10Z" fill="#323232"/>
                                    </svg>
                                    </>
                                  )}
                                  {a.type == "dataStory" && <>
                                    <GoalBadge
                                      style={{ marginLeft: "6px" }}
                                      color={sdgColors["SDG" + goal]}
                                      count={`Data Story`}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#323232"/>
                                      <path d="M14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z" fill="#323232"/>
                                    </svg>
                                    </>
                                  }
                                </span>
                              </TargetName>
                            }
                          >
                            <ChartCarousel
                              topics={[item.key] || []}
                              placeDcid="Earth"
                              name={item.label}
                              codes={a.key}
                              limit={200}
                            />
                          </Panel>
                        </IndicatorLink>
                      );
                    })}
                  </div>
                )}
              </Panel>
            </GoalItem>
          );
        })}
    </>
  );
};
export default GoalTable;

export const PanelHeader: React.FC<{
  url: string | undefined;
  label: string | undefined;
  icon: string | undefined;
  count: number | undefined;
  toggleCharts: boolean;
  sortDesc?: boolean;
  setSortDesc?: () => void;
  expandAll?: () => void;
  collapseAll?: () => void;
  onHeaderClick?: () => void;
}> = ({
  url,
  label,
  icon,
  count,
  toggleCharts,
  sortDesc,
  setSortDesc,
  expandAll,
  collapseAll,
  onHeaderClick,
}) => {
  return (
    <GridHeader>
      <Row gutter={8}>
        <div>
          <Tooltip placement="top" title={`Total ${label} indicators`}>
            {icon && (
              <Col>
                <img src={`${icon}`} />
              </Col>
            )}
            <Col span={19}>
              <Link onClick={onHeaderClick} to={url}>
                <strong>{label}</strong>
              </Link>
            </Col>
          </Tooltip>
        </div>
        <div className="filters">
          <Col style={{ display: "inline-flex" }}>
            <span style={{ fontSize: "12px" }}>
              Found &nbsp;<strong>{count}</strong>&nbsp; indicator(s)
            </span>
          </Col>
          <Col>
            {!toggleCharts ? (
              <Tooltip placement="top" title="List view">
                <AppstoreOutlined onClick={expandAll} />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Chart view">
                <UnorderedListOutlined onClick={collapseAll} />
              </Tooltip>
            )}
          </Col>
          <Col>
            {sortDesc ? (
              <Tooltip placement="top" title="Descending order">
                <UpOutlined onClick={setSortDesc} />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Ascending order">
                <DownOutlined onClick={setSortDesc} />
              </Tooltip>
            )}
          </Col>
        </div>
      </Row>
    </GridHeader>
  );
};

const Bullet = styled.span`
    background: ${({ color }) => color || "#fff"};
    width: 6px;
    min-width: 6px;
    height: 6px;
    border-radius:8px;
    margin: 7px 7px 0 22px;
`
const GoalBadge = styled(Badge)`
  margin: 0 8px 0 0;
  .ant-scroll-number {
    border-radius: 4px;
    border: 1px solid ${({ color }) => color || "#1890ff"};
    background: ${({ color }) => color || "#fff"};
  }
`;
export const ExpandIcon = styled.span`
  font-size: 18px !important;
  margin-right: 0 !important;
  transition: transform 0.3s ease;
  cursor: pointer;
  &.expanded {
    transform: rotate(90deg);
  }
`;
const IndicatorLink = styled.span`
  .ant-scroll-number {
    background: transparent !important;
    color: ${({ color }) => color || "#000"};
  }
`;
const GoalItem = styled(Collapse)`
  border: none;
  background-color: transparent;
  > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-header {
    padding: 3px !important;
    .ant-collapse-header-text {
      padding-left: 24px;
    }
    .ant-row {
      padding-left: 28px !important;
      margin: 0 0 1rem !important;
      transition: all 0.3s;
      border-bottom: none !important;
    }
    &[aria-expanded="false"] {
      .ant-row {
        border-color: #fff;
        margin: 0 0 !important;
      }
      .ant-collapse-header-text .ant-scroll-number {
      }
    }
    &[aria-expanded="true"] > .ant-collapse-header-text .ant-scroll-number {
    }
    img {
      height: 24px;
    }
  }
  .ant-collapse-expand-icon {
    padding-left: 6px;
    position: absolute;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
const AreaCollapse = styled(Collapse)`
  border-left: none;
  border-right: none;
  > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 3px !important;
    .ant-row {
      padding-left: 28px;
      margin: 0 0 !important;
      transition: all 0.3s;
      border-bottom: none;
    }
    &[aria-expanded="false"] .ant-row {
      border-color: #fff;
      margin: 0 0 !important;
    }
    img {
      height: 24px;
    }
  }
  .ant-collapse-expand-icon {
    padding-left: 6px;
    position: absolute;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
const TargetName = styled.div`
  padding: 4px 0;
  display: inline-flex;
`;
