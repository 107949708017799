import {
  DownloadOutlined,
  LinkOutlined,
  MoreOutlined
} from "@ant-design/icons";
import type { RadioChangeEvent } from 'antd';
import { Badge, Card, Dropdown, MenuProps, Space } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SubAreacard } from "../areas/ThematicAreaView";

const InfographicView: React.FC<{id: string}> = ({id}) => {
  const [size, setSize] = useState<string>('medium')
  const items: MenuProps["items"] = [
    {
      label: "Download",
      key: "0",
      icon: <DownloadOutlined />,
    },
    {
      label: "Copy sharable link",
      key: "1",
      icon: <LinkOutlined />,
    },
  ];
  const label =
    "Proportion of population covered by social protection floors/systems, by sex, distinguishing children, unemployed persons, older persons, persons with disabilities, pregnant women, newborns, work-injury victims and the poor and the vulnerable";
  const history = useHistory();
  const options = [
    { label: '60/40', value: 'large' },
    { label: '50/50', value: 'medium' },
    { label: '40/60', value: 'small' },
  ];
  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setSize(value);
  };
  return (
    <InfoBody>
      {/* <IndicatorHeader>
        <Row>
          {
            <Col flex="0 1">
              <BackButton size="small" onClick={history.goBack}>
                <ArrowLeftOutlined />
              </BackButton>
            </Col>
          }
          <Col flex="1 1">
            <span>{label}</span>
          </Col>
        </Row>
      </IndicatorHeader> */}
      {/* <InfographicSize>
        <Radio.Group
          options={options}
          onChange={onChange}
          value={size}
          optionType="button"
          buttonStyle="solid"
        />
      </InfographicSize> */}
      <SubAreacard
        style={{
          backgroundColor: "transparent",
          margin: 0,
          padding: "0 0 50px",
        }}
      >
        <VerticalFlex vertical={false} className={size}>
          <div className="cover">
            <img src="./images/infographic_1.png" />
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space size="large">
                  <MoreOutlined size="large" />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="label">
            <InfoBadge color="#E5253B" count="1.1.1" /> {label}
          </div>
        </VerticalFlex>
        {/* <Flex vertical={false}>
          <div className="text">
            <Col>
              <strong>Image Name: </strong> <span>infographic_010301.png</span>
            </Col>
            <Col>
              <strong>Image URL: </strong>{" "}
              <span>
                https://unstats.un.org/sdgs/report/2024/img/infographics/
                infographic_010301.png
              </span>
            </Col>
          </div>
          <div className="text">
            <Col>
              <strong>Indicator: </strong>
              <span>
                1.3.1 Proportion of population covered by social protection
                floors/ systems, by sex, distinguishing children, unemployed
                persons, older persons, persons with disabilities, pregnant
                women, newborns, work-injury victims and the poor and the
                vulnerable
                <br />
              </span>
            </Col>
            <Col>
              <Link
                to={`https://unstats.un.org/UNSDWebsite/undatacommons/goals?v=dc%2Ftopic%2Fsdg_1.3.1`}
              >
                https://unstats.un.org/UNSDWebsite/undatacommons/goals?v=dc%2Ftopic%2Fsdg_1.3.1
              </Link>
            </Col>
          </div>
        </Flex> */}
      </SubAreacard>
    </InfoBody>
  );
};

export default InfographicView;

const InfographicSize = styled.div`
  padding: 0 16px;
  text-align: center;
`
const InfoBody = styled.div`
  > * {
    margin: 0;
  }
`;
const InfoBadge = styled(Badge)`
  margin-right: 6px;
  .ant-scroll-number {
    border-radius: 4px;
  }
`;
const Flex = styled(Card)`
    border: none!important;
    .ant-card-body {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        background: #FEF2F3;
        padding:0;
        border: solid #fff 16px;
    }
    .cover {
        border-right: solid #fff 16px;
        position: relative;
        width: 60%;
        transition: all .3s;
        img {
            width: 100%;
        }
    }
    .label {
        padding: 24px;
        height: 100%;
        text-align: center;
        font-size: 17px;
        transition: all .3s;
        width: 40%;
    }
      &.medium {
        .cover {
          width: 50%;
        }
        .label {
            font-size: 17px;
            width: 50%;
        }
      }
      &.small {
        .cover {
          width: 40%;
        }
        .label {
            font-size: 17px;
            width: 60%;
        }
      }
    .text {
        background: #fff;
        width: 50%;
        height: inherit;
        .ant-col {
            margin-bottom: 16px;
        }
    }
    .ant-dropdown-trigger {
        position: absolute;
        right:0;
        top: 16px;
        svg {
        height: 1.3rem;
        width: 1.3rem;
    }
`;

const VerticalFlex = styled(Flex)`
    .ant-card-body {
        align-items: center;
    }

`