/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ColorBar, HomeSearchContainer, HomeSection, SectionDescription, SectionHeader } from "./components";
// @ts-ignore
import { Input } from "antd";
import { routeDataCommonsConstants } from "../../../../../src/helper/Common/RoutePathConstants";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { base64Encode } from "../areas/ThematicAreaView";
import { serializeGoalParams } from "../goals/GoalContent";
import { CountrySelect } from "../shared/components";
const {Search} = Input
const {routePathConstants} = routeDataCommonsConstants

export const PlaceSection = () => {
  const history = useHistory();

  const onSearch = (value:string) => {
    let str = serializeGoalParams({que: value, topics: [ROOT_TOPIC], location: [EARTH_PLACE_DCID]})
    history.push(`${routeDataCommonsConstants.GOAL}?p=`+base64Encode(str))
  }
  const onChange = (value) => {
    let str = serializeGoalParams({location: [value]})
    let str64 = base64Encode(str);
    history.push(routeDataCommonsConstants.GOAL + "?p=" + str64);
  }
  const countrySelectStyle = {
    width: '100%',
    border: 'solid #d9d9d9 1px',
    borderRadius: '25px'
  }
  return (
    <>
      <ColorBar />
      <Container>
        <SearchCard>
          <Header>Explore SDG Data by Countries or Areas</Header>
          <SearchBarContainer>
            <CountrySelectStyled
              setSelectedPlaceDcid={(placeDcid) => onChange(placeDcid)}
              style={countrySelectStyle}
            />
            {/* <SearchWrapper> */}
            {/* <Search 
              // prefix={<SearchOutlined />} 
              style={{ width: "100%" }} 
              // value={filterParams.que} 
              onSearch={onSearch} 
              placeholder="Search"
            /> */}
            {/* </SearchWrapper> */}
          </SearchBarContainer>
          <SectionDescription>Learn about country and SDG region progress on the UN SDGs through the UN Data Commons.</SectionDescription>
        </SearchCard>
      </Container>
      <ColorBar />
    </>
  );
};


const CountrySelectStyled = styled(CountrySelect)`
  width: 100%!important;
  [width="fit-content"]{
  width: 100%!important;
  }
`

const Container = styled(HomeSection)`
  background: rgb(213, 235, 245);
  background-image: url("./images/datacommons/un-homepage-map.png");
  background-repeat: no-repeat;
  background-position: center;
  padding-left:16px;
  padding-right:16px;
`;

const Header = styled(SectionHeader)`
  color: #449bd5;
  margin-bottom: 30px;
`;

const SearchCard = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(3, 7, 18, 0.04), 0px 1px 24px rgba(3, 7, 18, 0.08);
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  padding: 3rem 2rem;
`;

const SearchBarContainer = styled(HomeSearchContainer)`
  margin-bottom: 16px;
  .ant-input-wrapper {
    position: relative;
    height: 43px;
    .ant-input {
      height: 100%;
      border-radius: 30px !important;
      border-color: #449bd5!important;
    }
    .ant-input-group-addon {
      z-index: 5;
      position: absolute;
      left: auto!important;
      right: 32px!important;
      button {
        background: transparent;
        height: 43px;
        border-right: none;
        border-top: none;
        border-bottom: none;
        border-radius: 0 30px 30px 0!important;
      }
    }
  }
  .ant-select.ant-select-auto-complete.-dc-place-search {
    .ant-select-selector {
      height: 100% !important;
      display: flex;
      align-items: center;
      font-size: 16px;
      border: 0;
      border-radius: 30px !important;

      .ant-select-selection-search {
        height: 100% !important;
        display: flex;
        align-items: center;

        input {
          height: 100% !important;
          padding-left: 10px;
        }
      }

      .ant-select-selection-placeholder {
        height: 100% !important;
        font-style: italic;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const CountrySelectCard = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  h3 {
    font-size: 2rem;
    padding-bottom: 6px;
    padding-top: 6px;
    justify-content: space-between;
    font-weight: normal;
  }
  @media (min-width: 992px) {
    padding: 1.5rem;
    margin: 1.5rem;
  }
`;