import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { EARTH_PLACE_DCID } from "../../utils/constants";
import { MultipleSelect } from "../shared/components";
import { FilterBtn, FilterWrapper, OpenKeysContext } from "./ThematicAreaView";

const FilterOptions = ({ 
    position, 
    filterParams, 
    treeData, 
    thematicAreas, 
    partnerList,
    setFilterPosition, 
    onLocationChange,
    onThematicChange,
    onPartnersChange
  }) => {

  const {openKeys, setOpenKeys} = useContext(OpenKeysContext);

  useEffect(()=>{
      if ( filterParams.thematics?.length == 1 && filterParams?.thematics && !filterParams?.thematics?.includes('0')) {
        if(!openKeys?.includes(filterParams.thematics[0]))
        setOpenKeys(filterParams.thematics)
      } 
    },[filterParams.thematics])

  const handleFilter = (inputValue, treeNode) => {
    let title = treeNode.title || treeNode.label;
    const matchByIndex = treeNode.key?.toString().includes(inputValue);
    const matchByTitle = title?.toLowerCase().includes(inputValue.toLowerCase());
    return matchByIndex || matchByTitle;
  };

  const handleThematicChange = (thematics) => {
    if(thematics?.length == 1 && !thematics.includes('0'))
      setOpenKeys(thematics)
    else setOpenKeys([])
    onThematicChange({ thematics })
  }

  return (
    <FilterWrapper>
      {position == "left" && (
        <div className="sidebar-title">
          Filter content
          <FilterBtn setFilterPosition={setFilterPosition} />
        </div>
      )}
      <Row className={"position-" + position} gutter={[12, 12]}>
        {position == "top" && (
          <Col span={1}>
            <FilterBtn setFilterPosition={setFilterPosition} />
          </Col>
        )}
        <Col xs={24} md={6}>
          <MultipleSelect
            value={filterParams.location}
            placeholder={`Location | World`}
            filterTreeNode={handleFilter}
            onChange={(location) => onLocationChange({ location })}
            treeData={treeData}
            exeptionValue={EARTH_PLACE_DCID}
            isSingle={true}
          />
        </Col>
        <Col xs={24} md={9}>
          <MultipleSelect
            value={filterParams.thematics}
            placeholder={`Thematic Areas | All`}
            filterTreeNode={handleFilter}
            onChange={(thematics) => handleThematicChange(thematics)}
            treeData={thematicAreas}
            exeptionValue={"0"}
            isSingle={false}
            treeDefaultExpandAll
          />
        </Col>
        <Col xs={24} md={6}>
          <MultipleSelect
            value={filterParams.partners}
            placeholder={`Partners | All`}
            filterTreeNode={handleFilter}
            onChange={(partners) => onPartnersChange({ partners })}
            treeData={partnerList}
            exeptionValue={"all"}
            isSingle={false}
            treeDefaultExpandAll
          />
        </Col>
      </Row>
    </FilterWrapper>
  );
};

export default FilterOptions