import { Menu } from "antd";
import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../helper/Common/RoutePathConstants";
import { isMobile } from "../../../script/Commonfunctions";

const getMenuMode = () => {
  if (isMobile()) {
    return "inline";
  } else {
    return "horizontal";
  }
};

export default class TopNavigation extends Component {
  // all submenu keys of first level
  rootSubmenuKeys = [""];
  constructor(props) {
    super(props);
  }
  state = {
    current: "home",
  };

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  componentDidMount() {
    const history = createBrowserHistory();
    let route = history.location.pathname;

    if (route === routeDataCommonsConstants.COUNTRY) {
      this.setState({ current: "countries" });
    } else if (route === routeDataCommonsConstants.SDG_HOME || route.includes("goals")) {
      this.setState({ current: "landing" });
    } else if (route === routeDataCommonsConstants.SEARCH) {
      this.setState({ current: "search" });
    } else if (route.includes("areas")) {
      this.setState({ current: "areas" });
    } else if (route.includes("partners")) {
      this.setState({ current: "partners" });
    } else {
      this.setState({ current: "home" });
    }
  }


  render() {
    const { current } = this.state;

    return (
      <MainMenu>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[current]}
          mode={getMenuMode()}
          theme="dark"
        >
          <Menu.Item key="home">
            <NavLink
              to={`${routeDataCommonsConstants.HOME_PATH}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Home
            </NavLink>
          </Menu.Item>
          {/*<Menu.Item key="countries">
            <NavLink
              to={`${routeDataCommonsConstants.COUNTRY}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Countries / Areas
            </NavLink>
          </Menu.Item>*/}
          <Menu.Item key="areas">
            <NavLink
              to={`${routeDataCommonsConstants.THEMATIC_AREAS}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Thematic Areas
            </NavLink>
          </Menu.Item>
          <Menu.Item key="landing">
            <NavLink
              to={`${routeDataCommonsConstants.SDG_HOME}`}
              className="-dc-nav-link nav-link"
              exact
            >
              SDGs
            </NavLink>
          </Menu.Item>

          <Menu.Item key='partners'>
            <NavLink
              to={`${routeDataCommonsConstants.PARTNER}`}
              className='-dc-nav-link nav-link'
            >
              Data Partners
            </NavLink>
          </Menu.Item>

          <Menu.Item key="search">
            <NavLink
              to={`${routeDataCommonsConstants.SEARCH}`}
              className="-dc-nav-link nav-link"
              exact
            >
              Search
            </NavLink>
          </Menu.Item>
          <Menu.Item key="help" style={{ marginLeft: "auto" }}>
            <a
              style={{ borderLeft: "none", borderLeft: "1px solid #fff" }}
              href="https://docs.google.com/document/d/e/2PACX-1vTgc1MzJ0u7J3bBGZXVhq56Hnf9pxF_yKFiWbQXhASsBzVZR8GSxhh5Cw3JytskWHo50jl7INtY-FEY/pub"
              className="-dc-nav-link nav-link"
              rel="noopener noreferrer"
              target="_blank"
            >
              Help
            </a>
          </Menu.Item>
        </Menu>
      </MainMenu>
    );
  }
}

const MainMenu = styled.div`
  .ant-menu-item-selected a {
    color: #4d4d4d !important;
    background-color: #F2F2F2;
  }
`