import { ArrowLeftOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, message, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state";
import { ExpandIcon, IndicatorTable } from "./AreaTable";
import Indicator from "./Indicator";
import { BackButton, OpenKeysContext, SubAreacard, ThematicIndicatorHeader } from "./ThematicAreaView";

const ExploreSubTheme: React.FC = ({ data, filterParams, partnerList, areaId }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<[]>([]);
  const [listView, setListView] = useState<boolean>(false);
  const store = useStoreState((s) => s);
  const history = useHistory();
  const location = useLocation();
  const { backUrl } = useContext(OpenKeysContext);
  const [messageApi, contextHolder] = message.useMessage();

  //pagination with state
  const STORAGE_KEY = `pagination-${data?.id}`;
  const loadPagination = () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : { current: 1, pageSize: 15 };
  };
  const [pagination, setPagination] = useState(loadPagination);
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    if (data?.children && data?.id) {
      let tmpIds = getAllKeys(data);
      setExpandedRowKeys(tmpIds);
    }
  }, [data?.children]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Item",
      dataIndex: "indicator_name",
      key: "name",
      render: (text, record) => {
        let link = "";
        if (typeof record.key != "undefined") {
          link = `#explore+${record.key}`;
        } else {
          link = `#explore+${data.id}+${record.id}`;
        }
        record.link = link;

        return <a>{text}</a>;
      },
    },
    {
      title: "Partner",
      dataIndex: "agency",
    },
    {
      // title: 'Action',
      dataIndex: "operation",
      key: "operation",
      width: 28,
      render: (text, record) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                {/* <Menu.Item key="1"><DownloadOutlined /> Download</Menu.Item> */}
                <CopyBtn item={record} />
              </Menu>
            }
            trigger={["click"]}
          >
            <a>
              <span className="material-icons">more_vert</span>
            </a>
          </Dropdown>
        </>
      ),
    },
  ];

  const CopyBtn = ({ item }) => {
    const link = `${window.location.origin}${routeDataCommonsConstants.THEMATIC_AREAS}/${areaId}${item.link}`;
    return (
      <Menu.Item onClick={() => handleCopy(link)} key="2">
        <LinkOutlined /> Copy sharable link
      </Menu.Item>
    );
  };

  const handleCopy = async (link) => {
    message.destroy();
    try {
      await navigator.clipboard.writeText(link);
      messageApi.open({
        type: "success",
        content: "Copied!",
      });
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  const goBack = () => {
    if (!backUrl) goList();
    else history.push(backUrl);
  };

  const goList = () => {
    history.push(location.pathname || routeDataCommonsConstants.THEMATIC_AREAS);
  };

  const getAllKeys = (data) => data.children.map((a) => a.id);

  const onExpand = (expanded, record) => {
    setExpandedRowKeys((keys) => (expanded ? [...keys, record.id] : keys.filter((key) => key !== record.id)));
  };

  const expandAll = () => {
    setExpandedRowKeys(listView ? getAllKeys(data) : []);
    setListView(!listView);
  };

  let partnerCodes = partnerList?.[0].children?.map((a) => a.value);
  const items = data?.indicatorId
    ? data?.children?.filter((a) => a.id == data.indicatorId)
    : data?.children?.filter((item) => {
        let partnersCheck =
          !item.agency ||
          ((!filterParams?.partners?.length || filterParams.partners.includes("all") || filterParams.partners.includes(item.agency)) &&
            partnerCodes.includes(item.agency));

        let locationCheck =
          !item.indicator_codes ||
          filterParams.location.includes("Earth") ||
          !filterParams?.location?.length ||
          !filterParams?.statVars?.length ||
          filterParams?.statVars.some((i) => item?.indicator_codes.includes(i));
        return partnersCheck && locationCheck;
      });

  const urlParams = [`#carousel`, data?.id, data?.indicatorId].filter((a) => !!a);
  return (
    <SubThemeWrapper>
      {contextHolder}
      <ThematicIndicatorHeader>
        <Row>
          {data?.label && (
            <Col flex="0 1">
              <BackButton size="small" onClick={goBack}>
                <ArrowLeftOutlined />
              </BackButton>
            </Col>
          )}
          <Col flex="1 1">
            <span>{data?.label}</span>
          </Col>
          {
            <Col flex="0 1">
              <div className="index-text">
                <span>{data?.indicatorId ? items?.[0]?.indicator_codes?.length : items?.length} Item(s)</span>
                <Link to={urlParams.join("+")}>
                  <span className="material-icons-outlined">view_carousel</span>
                </Link>

                <Button onClick={expandAll}>
                  {listView ? <span className="material-icons">list</span> : <span className="material-icons">grid_view</span>}
                </Button>
              </div>
            </Col>
          }
        </Row>
      </ThematicIndicatorHeader>

      <StyledTable
        color={data.color}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return (
              record.indicator_codes?.length && (
                <SubAreacard>
                  {(!data.indicatorId ? [record.indicator_codes[0]] : record.indicator_codes).map((code) => (
                    <Indicator key={code} placeDcid={filterParams.location[0] || "Earth"} store={store} name={record.indicator_name} code={code} limit={1} />
                  ))}
                </SubAreacard>
              )
            );
          },
          expandIconColumnIndex: columns?.length - 1,
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandIcon onClick={(e) => onExpand(record, e)} className={`material-icons ${expanded && `expanded`}`}>
              chevron_right
            </ExpandIcon>
          ),
          rowExpandable: (record) => record.indicator_codes?.length,
          onExpand,
          expandedRowKeys,
        }}
        rowKey={"id"}
        dataSource={items}
        pagination={
          items && items.length > 15
            ? {
                current: pagination.current,
                pageSize: pagination.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ["10", "15", "20", "50", "100"],
                onChange: (page, pageSize) => {
                  setPagination((prev) => ({
                    ...prev,
                    current: page,
                    pageSize: pageSize,
                  }));
                },
              }
            : false
        }
      />
    </SubThemeWrapper>
  );
};

export default ExploreSubTheme;

const StyledTable = styled(IndicatorTable)`
  .ant-table-pagination.ant-pagination {
    margin-right: 2.5rem !important;
  }
`;
const SubThemeWrapper = styled.div`
  .ant-table-content {
    margin: 0 2rem 0 3.5rem !important;
    th {
      background: #fff;
      padding: 7px 0;
      &:before {
        display: none;
      }
    }
    tr {
      td {
        &:first-child {
        }
      }
    }
    .ant-table-expanded-row td > div {
      padding: 0 0;
      .-dc-indicator-chart {
        padding-left: 0 !important;
      }
    }
    .ant-table-cell:first-child:before {
      left: 0;
    }
    td {
      vertical-align: middle;
    }
    .material-icons {
      font-size: 20px !important;
    }
    @media (max-width: 768px) {
      margin: 0 0.75rem 0 1rem !important;
    }
  }
  .ant-table-row .ant-table-cell:first-child {
    padding-left: 24px;
  }
`;
