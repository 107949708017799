import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";

const MarkdownWrapper = styled.div`
  max-width: 816px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 250px;

  h1 {
    color: #283460;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Roboto Condensed";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    padding: 16px 0 32px 0;
  }

  h2 {
    color: var(--un-primary-absolute-black-75, #404040);
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Roboto Condensed";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 1rem 0;
  }

  h2:after {
    content: "";
    width: 90px;
    height: 3px;
    background-color: #009edb;
    display: block;
    margin-top: 5px;
  }

  p {
    color: #4d4d4d;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.28px;
  }

  ul {
    margin-left: 1.5rem;
    list-style: disc;
    line-height: 1.6;
  }

  ol {
    margin-left: 1.5rem;
    list-style: decimal;
    line-height: 1.6;
  }

  a {
    color: #0a5a82;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
    border: solid 1px #c4c4c4;
  }
  .unblog-heading {
    color: #4d4d4d;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Roboto Condensed";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  .author {
    color: var(--unc-absolute-black-75, #404040);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .date {
  }
`;

const Blog: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/blog/undata_blog_02.md`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the Markdown file.");
        }
        return response.text();
      })
      .then((text) => setContent(text))
      .catch((error) => console.error("Error loading the Markdown file:", error));
  }, []);

  return (
    <AppLayoutContent>
      <Layout className="container" style={{ backgroundColor: "transparent" }}>
        <AppBreadcrumb>Blog</AppBreadcrumb>
        <MarkdownWrapper>
          <h3 className="unblog-heading">UN Data Blog</h3>
          <ReactMarkdown>{content}</ReactMarkdown>
        </MarkdownWrapper>
      </Layout>
    </AppLayoutContent>
  );
};

export default Blog;
