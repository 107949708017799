import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Input, Layout, Row, TreeSelect } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreActions, useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { base64Decode, base64Encode, FilterBtn, FilterParams, FilterWrapper, SearchWrapper } from "../areas/ThematicAreaView";
import { getGoalTargetIndicator, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import ExploreIndicator from "./ExploreIndicator";
import GoalTable from "./GoalTable";
import IndicatorCarousel from "./IndicatorCarousel";
const { SHOW_PARENT } = TreeSelect;
export const unSort = (a:any, b:any) => {
  const [numA, suffixA] = a.split('.');
  const [numB, suffixB] = b.split('.');
  const numComparison = parseInt(numA) - parseInt(numB);
  if (numComparison !== 0) {
      return numComparison;
  }
  // Handle the case where suffix is numeric
  if (!isNaN(suffixA) && !isNaN(suffixB)) {
      return parseInt(suffixA) - parseInt(suffixB);
  }
  // Handle the case where suffix is alphabetic
  if (isNaN(suffixA) && isNaN(suffixB)) {
      return suffixA.localeCompare(suffixB);
  }
  // Handle mixed case: numeric suffixes should come before alphabetic suffixes
  return isNaN(suffixA) ? 1 : -1;
}


const GoalContent: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    selectedVariableDcids: string[];
    variableDcids: string[];
    isSearch: boolean;
    hideGoalOverview?: boolean;
    hideTargetHeader?: boolean;
    blockID?: number | undefined
  }> = ({fulfillResponse, placeDcids, variableDcids}) => {
    const location = useLocation()
    const history = useHistory();
    const store = useStoreState((s) => s);
    const params = Object.fromEntries(new URLSearchParams(location.search))
    const rootTopics = useStoreState((s) => s.rootTopics);
    const [localIsFetchingFulfillment, setLocalIsFetchingFulfillment] = useState(false);
    const [localFulfillResponse, setLocalFulfillResponse] = useState<FulfillResponse>();
    const fetchTopicFulfillment = useStoreActions((a) => a.fetchTopicFulfillment);
    const fulfillmentsById = useStoreState((s) => s.fulfillments.byId);const countries = useStoreState((s) => s.countries.dcids.map((dcid) => s.countries.byDcid[dcid]));
    const regions = useStoreState((s) => s.regions.dcids.map((dcid) => s.regions.byDcid[dcid]));
    const [filterPosition, setFilterPosition] = useState<string>("top");
    const [filterParams, setFilterParams] = useState<FilterParams>({
      que: "",
      location: ["Earth"],
      partners: ["all"],
      topics: ["dc/topic/sdg"],
      statVars: [],
    });
    const [carouselProps, setCarouselProps] = useState<[]>([])
    const [topics, setTopics] = useState([]);
    const [varToTopics, setVarToTopics] = useState({});
    const [goal, target, indicator] = params.v ? getGoalTargetIndicator(params.v) : []


    useEffect(()=>{
      let v
      let tmpParams = {...filterParams}
      try {
        v = base64Decode(params.p);
        tmpParams.location = v.split("&")[0] ? v.split("&")[0].split(",") : ["Earth"]
        tmpParams.topics = v.split("&")[1] ? v.split("&")[1].split(",").map(a=>ROOT_TOPIC==a ? a : ROOT_TOPIC+'_'+a) : [ROOT_TOPIC]
      } catch {
        // slug = path.slice(-1)[0];
        // params.thematics = slug.split(",");
      }
      setFilterParams({...tmpParams})
      // if(goal && goal !=='none')
        // setFilterParams({...filterParams, topics: [params.v]})
    },[window.location.search]);

    
    useEffect(() => {
      fetch(`${process.env.PUBLIC_URL}/config/sidebar.json`)
        .then((response) => response.json())
        .then((data) => {
          let list = formatTopic(data)
          setTopics(list);
        })
        .catch((error) => console.error("Error loading sidebar.json:", error));
    }, []);


    useEffect(() => {

      // document.getElementById(`top`)?.scrollIntoView({ behavior: "smooth" });
      // If a fulfill response was passed in, use that
      // Otherwise fetch a fulfill response based on the specified variables & place
      if (!variableDcids || variableDcids.length === 0 || placeDcids.length === 0) {
        return;
      }
      (async () => {
        setLocalIsFetchingFulfillment(true);
        const topicDcids = variableDcids.map((dcid) => {
          if (dcid.indexOf("/g/") !== -1) {
            return dcid.replace("/g/", "/topic/").toLocaleLowerCase();
          }
          return dcid;
        });

        const fulfillment = await fetchTopicFulfillment({
          entityDcids: filterParams.location || placeDcids,
          variableDcids: topicDcids,
          fulfillmentsById,
        });
        setLocalIsFetchingFulfillment(false);
        setLocalFulfillResponse(fulfillment);
      })();
    }, [fulfillResponse, 
      // placeDcids, 
      // variableDcids, 
      filterParams.location]);

    useEffect(()=>{

      if(!localFulfillResponse?.relatedThings?.varToTopics) return;
      let t = Object.entries(localFulfillResponse?.relatedThings?.varToTopics).reduce((acc, [key, value]) => {
          const dcid = value[0].dcid;
          if (!acc[dcid]) {
            acc[dcid] = [];
          }
          acc[dcid].push(key);
          
          return acc;
      }, {});
      setVarToTopics(t)

  },[localFulfillResponse])


  const formatTopic = (data) => {
    return data.filter(a=>!a.key.includes('summary-')).map(a=>{
      let children = a.children ? formatTopic(a.children) : []
      return {...a, value: a.key, children, parents:[]}
    })
  }


    const options = [countries, regions].flat().map((place) => ({ value: place.dcid, title: place.name, id:place.dcid }));
    options.sort(({ value: value1 }, { value: value2 }) => value1.localeCompare(value2));
    
    const treeData = [
      {
        title: "World",
        value: "Earth",
        key: "0",
      },
      ...options
    ];
    
    const onTopicChange = (e) => { 
      const added = e.find((item) => !filterParams.topics.includes(item));
      const topics = filterParams.topics?.length && e.some((i: string) => i == ROOT_TOPIC) ? (added == ROOT_TOPIC ? [ROOT_TOPIC] : e.filter((i) => i != ROOT_TOPIC)) : e;
      nestedSearch({ ...filterParams, topics});
    }


    const onLocationChange = (e) => {
      let statVars: string[] = [];
      const added = e.find((item) => !filterParams.location.includes(item));
      const location = filterParams.location?.length && e.some((i: string) => i == EARTH_PLACE_DCID) ? (added == EARTH_PLACE_DCID ? [EARTH_PLACE_DCID] : e.filter((i) => i != EARTH_PLACE_DCID)) : e;
      nestedSearch({ ...filterParams, location, statVars });
    };

    const queSearch = (e) => {
      nestedSearch({ ...filterParams, que: e.target.value });
    }

    const nestedSearch = (params: FilterParams) => {
      setFilterParams(params);
      let str = [`${params.location.join(",")}`, `${params.topics.map(t=>t.replace(ROOT_TOPIC+'_', '')).join(",")}`].join("&");
      let str64 = base64Encode(str);
      history.push(routeDataCommonsConstants.GOAL + "?p=" + str64);
    };


    const FilterOptions = ({ position }) => {

      const handleFilter = (inputValue, treeNode) => {
        let title = treeNode.title || treeNode.label
        const matchByIndex = treeNode.key?.toString().includes(inputValue);
        const matchByTitle = title?.toLowerCase().includes(inputValue.toLowerCase());
        return matchByIndex || matchByTitle;
      };

      return (
        <FilterWrapper>
          {position == "left" && (
            <div className="sidebar-title">
              Filter content
              <FilterBtn setFilterPosition={setFilterPosition}/>
            </div>
          )}
          <Row className={"position-" + position} gutter={[12, 12]}>
            {position == "top" && (
              <Col span={1}>
                <FilterBtn setFilterPosition={setFilterPosition}/>
              </Col>
            )}
            <Col span={6}>
              <TreeSelect
                suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                showSearch
                style={{ width: "100%", display: "block", borderRadius: "50%" }}
                value={filterParams.location}
                treeCheckable
                showArrow
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={`Location | World`}
                allowClear
                treeDefaultExpandAll
                filterTreeNode={handleFilter}
                onChange={onLocationChange}
                treeData={treeData}
              />
            </Col>
            <Col span={8}>
              <TreeSelect
                suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                showSearch
                style={{ width: "100%", display: "block", borderRadius: "50%" }}
                // value={theme?.id}
                value={filterParams.topics}
                treeCheckable
                showArrow
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={`Goal | All`}
                allowClear
                filterTreeNode={handleFilter}
                onChange={onTopicChange}
                treeData={topics}
              />
            </Col>
          </Row>
        </FilterWrapper>
      );
    };


    const columns: ColumnsType<DataType> = [
      {
        title: "label",
        dataIndex: "label",
        key: "key",
        render: (text, record) => {
          if (typeof record.key != "undefined") {
            return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${record.themeId}#${record.key}`}>{text}</Link>;
          } else {
            const parent = findSubTheme(topics, record.key);
            return <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${record.themeId}#${parent.key}+${record.key}`}>{text}</Link>;
          }
        },
      },
      // {
      //   title: "Agency",
      //   dataIndex: "agency",
      // },
    ];
  
    const onItemClick = (props) => {
      // let p = props.codes.filter(a=>!a.type).map(a=>a.key).flat(1)
      // console.log(props,'vam')
      setCarouselProps({...props, setCarouselProps})
    }
    
    return <Layout className="ant-layout-has-sider" id="top">
              {filterPosition == "left" && <Sider theme="light" width={340}>
                <FilterOptions position={filterPosition} />
              </Sider>}
                <Layout.Content style={{ background: theme.searchBackgroundColor }}>
                    
                <div className="container">
                      <GoalSearchWrapper>
                        <Row gutter={[12, 12]}>
                          <Col span={filterPosition === "none" ? 23 : 24}>
                            
                            <Input prefix={<SearchOutlined />} style={{ width: "100%" }} value={filterParams.que} onChange={queSearch} placeholder="Search" />
                          </Col>
                          {filterPosition === "none" && (
                            <Col span={1}>
                              <FilterBtn setFilterPosition={setFilterPosition}/>
                            </Col>
                          )}
                        </Row>
                        {filterPosition == "top" && <FilterOptions position={filterPosition} />}
                      </GoalSearchWrapper>
                      {(indicator && indicator !== 'none' && goal && target && localFulfillResponse)
                      ? <ExploreIndicator
                          goal={goal} 
                          target={target} 
                          indicator={indicator} 
                          fulfillResponse={localFulfillResponse}
                          placeDcids={filterParams.location} 
                          setCarouselProps={setCarouselProps}
                      /> : <>
                      {carouselProps?.name ? <IndicatorCarousel {...carouselProps}/> :
                      <>{
                      Object.keys(topics).length && localFulfillResponse && !localIsFetchingFulfillment ? 
                      Object.values(topics)
                      // .sort((a, b) => {console.log(a,b,'c')
                      //     const numA = parseInt(a.match(/\d+/)[0]);
                      //     const numB = parseInt(b.match(/\d+/)[0]);
                      //     return numA - numB;
                      //     })
                          .filter(a=>{
                            let all = filterParams.topics.find(a=>a==ROOT_TOPIC)
                            return all ? a.key != ROOT_TOPIC : filterParams.topics?.map(a=>a.split('.')[0])?.includes(a.key)
                          })
                          // .filter((a, i)=>(goal && goal !='none' ? a.key == ROOT_TOPIC+'_'+goal : a.key != ROOT_TOPIC))
                          .map((i:string, topicIndex:number) => {
                            let defOpened = !topicIndex//!!(goal&&goal!='none') ? 2 : 1// || filterParams.thematics?.[0] != "0";
                            // let topic = topics[i]
                            return (
                                <GoalTable 
                                  setCarouselProps={onItemClick}
                                  placeDcids={[EARTH_PLACE_DCID]} 
                                  localFulfillResponse={localFulfillResponse} 
                                  columns={columns} 
                                  filterParams={filterParams} 
                                  key={`${topicIndex}-${defOpened}`} 
                                  area={i} 
                                  store={store} 
                                  opened={defOpened}
                                  varToTopics={varToTopics}
                                />
                              )
                          }
                        ):
                        <ContentCard><Spinner/></ContentCard>
                      }
                      </>}
                      </>}
                    </div>
                </Layout.Content>
            </Layout>
        
  }
export default GoalContent

const GoalSearchWrapper = styled(SearchWrapper)`
  margin: 1rem 0;
`